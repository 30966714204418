<script setup lang="ts">
import { useMainStepService } from 'share/services/main/useMainService'

const {
  isStepComplete
} = useMainStepService()
</script>

<template>
  <article class="p-top-24 p-bottom-24 p-x-40">
    <header class="flex items-center justify-between m-bottom-20">
      <h1 class="text-title2-b">Quick tips to make the most of The Pond!</h1>
    <h1 class="text-title2-b m-bottom-20">
    </h1>
    </header>
    <div class="flex">
        <article
          class="radius-16 bg-white p-x-20 p-top-16 p-y-16 p-bottom-20 box-border flex cursor-pointer m-right-12 flex-direction-column justify-between slide-content"
          :class="isStepComplete('STEP2') ? 'border-a-gray2-1': 'border-a-primary4-1'"
          style="width: calc(25% - 9px);"

        >
          <div>
            <mark
              class="radius-200 text-caption2 text-gray6 p-x-10 p-y-4 inline-block"
              :class="isStepComplete('STEP2') ? 'bg-t1': 'border-a-t2-1'"
            >
              <template v-if="isStepComplete('STEP2')">
                Completed
              </template>
              <template v-else>
                Step 1
              </template>
          </mark>
          <div>
            <p class="m-top-12 text-body2 text-gray6 text-body2-b text-black text-gray6 overflow-hidden text-overflow-ellipsis" style="display: -webkit-box; -webkit-line-clamp: 5; -webkit-box-orient: vertical;">
              Try our Career Survey and get personalized job recommendations based on your aptitudes!
            </p>
          </div>
        </div>
      
          <!--          완료한경우-->
          <UiButton
            @click="navigateTo('/career/survey')"
            class="w-100-p small outline"
            v-if="isStepComplete('STEP2')"
          >
            Retry the Career Survey
          </UiButton>
          <!--          완료한경우-->
          <!--          미완료-->
          <UiButton
            @click="navigateTo('/career')"
            class="w-100-p small outline"
            v-else
          >
            Take the Career Survey Now!
          </UiButton>
          <!--          미완료-->
        </article>
        <article
        class="radius-16 bg-white p-x-20 p-top-16 p-y-16 p-bottom-20 box-border flex cursor-pointer m-right-12 flex-direction-column justify-between slide-content"
          :class="isStepComplete('STEP2') ? 'border-a-gray2-1': 'border-a-primary4-1'"
          style="width: calc(25% - 9px);"

        >
        <div>
          <mark
            class="radius-200 text-caption2 text-gray6 p-x-10 p-y-4 inline-block"
            :class="isStepComplete('STEP1') ? 'bg-t1' : 'border-a-t2-1'"
          >
            <template v-if="isStepComplete('STEP1')">
              Completed
            </template>
            <template v-else>
              Step 2
            </template>
          </mark>
          <div>
            <p class="m-top-12 text-body2 text-gray6 text-body2-b text-black text-gray6 overflow-hidden text-overflow-ellipsis" style="display: -webkit-box; -webkit-line-clamp: 5; -webkit-box-orient: vertical;">
              Take the College Survey to receive personalized college recommendations based on your preferences!
            </p>
          </div>
        </div>
          <!--          완료한경우-->
          <UiButton
            @click="navigateTo('/collegesurvey')"
            class="w-100-p small outline"
            v-if="isStepComplete('STEP1')"
          >
            Retry the College Survey
          </UiButton>
          <!--          완료한경우-->
          <UiButton
            @click="navigateTo('/collegesurvey')"
            class="w-100-p small outline"
            v-else
          >
            Take the College Survey Now!
          </UiButton>
        </article>
      <!--      step3 의 경우 둘다 했을때 completed 처리-->
        <article
        class="radius-16 bg-white p-x-20 p-top-16 p-y-16 p-bottom-20 box-border flex cursor-pointer m-right-12 flex-direction-column justify-between slide-content"
          :class="isStepComplete('STEP2') ? 'border-a-gray2-1': 'border-a-primary4-1'"
          style="width: calc(25% - 9px);"
        >
          <div>
            <mark
              class="radius-200 text-caption2 text-gray6 p-x-10 p-y-4 inline-block"
              :class="isStepComplete('STEP3') ? 'bg-t1': 'border-a-t2-1'"
            >
              <template v-if="isStepComplete('STEP3')">
                Completed
              </template>
              <template v-else>
                Step 3
              </template>
          </mark>
          <div>
            <p class="m-top-12 text-body2 text-gray6 text-body2-b text-black text-gray6 overflow-hidden text-overflow-ellipsis" style="display: -webkit-box; -webkit-line-clamp: 5; -webkit-box-orient: vertical;">
              Find your desired colleges and careers, and add majors you're interested in into your Major List!
            </p>
          </div>
        </div>
          <div class="m-top-12">
            <UiButton
              @click="navigateTo('/college/find')"
              class="w-100-p small outline m-bottom-8"
              style="padding: 0;"
            >
              Go to Find a College
            </UiButton>
            <UiButton
              @click="navigateTo('/career/search')"
              class="w-100-p small outline m-bottom-8"
              style="padding: 0;"
            >
              Go to Find a Career
            </UiButton>
          </div>
        </article>
        <article
          class="radius-16 bg-white p-x-20 p-top-16 p-y-16 p-bottom-20 box-border flex cursor-pointer flex-direction-column justify-between slide-content"
          :class="isStepComplete('STEP2') ? 'border-a-gray2-1': 'border-a-primary4-1'"
          style="width: calc(25% - 9px);"
        >
          <div>
            <mark
              class="radius-200 text-caption2 text-gray6 p-x-10 p-y-4 inline-block"
              :class="isStepComplete('STEP4') ? 'bg-t1': 'border-a-t2-1'"
            >
              <template v-if="isStepComplete('STEP4')">
                Completed
              </template>
              <template v-else>
                Step 4
              </template>
          </mark>
          <div>
            <p class="m-top-12 text-body2 text-gray6 text-body2-b text-black text-gray6 overflow-hidden text-overflow-ellipsis" style="display: -webkit-box; -webkit-line-clamp: 5; -webkit-box-orient: vertical;">
              On the Roadmap page, select your Dream College & Major to receive a customized Roadmap tailored to your goals!
            </p>
          </div>
        </div>
          <UiButton
          
            @click="navigateTo('/roadmap')"
            class="w-100-p small outline"
          >
            <template v-if="isStepComplete('STEP4')">
              Track your roadmap progress
            </template>
            <template v-else>
              Get a Personalized Roadmap!
            </template>
          </UiButton>
        </article>
    </div>
  </article>
</template>