<template>
  <UiIconBase>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M6 7C6 6.44772 6.44772 6 7 6H17C17.5523 6 18 6.44772 18 7V22C18 22.5523 17.5523 23 17 23H7C6.44772 23 6 22.5523 6 22V7ZM8 8V21H16V8H8Z" fill="currentColor"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M16 10C16 9.44772 16.4477 9 17 9H20.5C21.0523 9 21.5 9.44772 21.5 10V22C21.5 22.5523 21.0523 23 20.5 23H17C16.4477 23 16 22.5523 16 22V10ZM18 11V21H19.5V11H18Z" fill="currentColor"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M9 17C9 16.4477 9.44772 16 10 16H14C14.5523 16 15 16.4477 15 17V22C15 22.5523 14.5523 23 14 23H10C9.44772 23 9 22.5523 9 22V17ZM11 18V21H13V18H11Z" fill="currentColor"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M2.5 10C2.5 9.44772 2.94772 9 3.5 9H7C7.55228 9 8 9.44772 8 10V22C8 22.5523 7.55228 23 7 23H3.5C2.94772 23 2.5 22.5523 2.5 22V10ZM4.5 11V21H6V11H4.5Z" fill="currentColor"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M11.5528 1.10557C11.8343 0.964809 12.1657 0.964809 12.4472 1.10557L18.4472 4.10557C18.786 4.27496 19 4.62123 19 5V7C19 7.55228 18.5523 8 18 8H6C5.44772 8 5 7.55228 5 7V5C5 4.62123 5.214 4.27496 5.55279 4.10557L11.5528 1.10557ZM7 5.61803V6H17V5.61803L12 3.11803L7 5.61803Z" fill="currentColor"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M2 21H22V23H2V21Z" fill="currentColor"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M10.5 11H9.5V9H10.5V11Z" fill="currentColor"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M10.5 14H9.5V12H10.5V14Z" fill="currentColor"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M14.5 11H13.5V9H14.5V11Z" fill="currentColor"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M14.5 14H13.5V12H14.5V14Z" fill="currentColor"/>
  </UiIconBase>
</template>

