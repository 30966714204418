<script setup>
import { useModal } from 'vue-final-modal'
import DetailActivityModal from '~/components/modal/main/ActivityDetail.vue'
import PremiumActivityList from '~/components/modal/main/PremiumActivityList.vue'
import { useMainRecommendedActivityService } from 'share/services/main/useMainService'
import { useMemberSessionStore } from 'share/stores/memberSessionStore'
import { storeToRefs } from 'pinia'
import Trophy_line from 'share/components/icon/Trophy_line.vue'

const { isLoggedIn } = storeToRefs(useMemberSessionStore())


const {
  recommendedActivityList
} = useMainRecommendedActivityService()

const handleShowActivityDetailModal = (id) => {
  const { open, close } = useModal({
    component: DetailActivityModal,
    attrs: {
      onCancel: () => {
        close()
      },
      id: id
    },
  })
  open()
}

const GoToExtracurricularSearch = (extracurricularName) => {
  navigateTo('/extracurriculars?name=' + extracurricularName )
}

const handleShowPremiumActivityModal = (id) => {
  const { open, close } = useModal({
    component: PremiumActivityList,
    attrs: {
      onCancel: () => {
        close()
      }
    },
  })
  open()
}

</script>

<template>
  <article v-if="recommendedActivityList?.length > 0" class="p-top-24 p-bottom-40 p-x-40">
    <h1 class="text-title2-b m-bottom-20">
      {{ isLoggedIn ? 'Nearby Extracurricular Activities for You' : 'Extracurricular Activities Directory' }}
    </h1>
    <div class="flex">
      <div
        v-for="(activity) in recommendedActivityList"
        :key="activity.ipedsCollegeId"
        class="radius-16 p-x-20 p-y-16 box-border border-a-gray2-1 bg-white m-right-12 cursor-pointer"
        @click="GoToExtracurricularSearch(activity.title)"
        style="width: calc(25% - 9px);"
      >
 
        <div class="flex justify-between flex-direction-column items-start h-100-p">
          <div>
            <UiLabel class="small border blue">
              Recommended
            </UiLabel>
            <strong class="text-body1-b m-y-12 text-clamp-2">
              {{ activity.title }}
            </strong>
            <p class="text-caption2 text-gray6 text-clamp-3 m-bottom-24" style="line-height: 16px">
              {{ activity.description }}
            </p>
          </div>
          <UiTextButton class="item-align--right flex">View More</UiTextButton>
        </div>
      </div>
    <article
        @click="navigateTo('/extracurriculars')"
        class="radius-16 p-x-20 border-a-primary2-2 bg-white  box-border flex flex-direction-column justify-center cursor-pointer"
        style="width: calc(25% - 9px);"
      >
        <h2 class="text-body1-b text-primary4 m-bottom-8 flex items-end">
          <Trophy_line width="32" height="32" viewBox="-2 -2 27 32" color="#99AFFF" class="m-right-8"/>
          See more activities!
        </h2>
        <p class="flex items-center justify-between text-caption1-b text-primary5">
          Go to Activity Search
          <img src="@share/assets/images/main/main-more-primary.svg" alt="">
        </p>
      </article>
    </div>

  </article>

</template>

<style scoped lang="scss">

</style>
