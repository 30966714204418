<script setup lang="ts">
import useMyCollegeService from 'share/services/college/useMyCollegeService'
import { useMainRecommendedCollegeService } from 'share/services/main/useMainService'
import { useMemberSessionStore } from 'share/stores/memberSessionStore'
import { storeToRefs } from 'pinia'

const { isLoggedIn } = storeToRefs(useMemberSessionStore())

const {
  handleThumbnailError,
  recommendedCollegeList
} = useMainRecommendedCollegeService()

const {
  myCollegeList,
  fetchMyCollegeList,
  handleAddMyCollege,
  handleDeleteMyCollege
} = useMyCollegeService()

onMounted(async () => {
  if (isLoggedIn.value) {
    await fetchMyCollegeList()
  }
})
</script>

<template>
  <article v-if="recommendedCollegeList?.length > 0" class="p-top-24 p-bottom-40 p-x-40">
    <header class="flex items-center justify-between m-bottom-20">
      <h1 class="text-title2-b">{{ isLoggedIn ? 'Colleges You Might Be Interested In' : 'College Exploration' }}</h1>
    </header>
    <div class="flex">
      <article
        v-for="(college, index) in recommendedCollegeList"
        :key="college.ipedsCollegeId"
        class="radius-16 border-a-gray2-1 box-border bg-white cursor-pointer m-right-12 p-bottom-20"
        @click="navigateTo(`/college/detail/${college.ipedsCollegeId}`)"
        style="width: calc(25% - 9px);"
      >
        <div class="flex justify-between overflow-hidden" style="display: flex; padding: 20px 16px; align-items: flex-start; gap: 8px; align-self: stretch;">
          <div class="college_name_container" style="max-height: 44px; align-self: stretch;">
            <strong class="text-body1-b text-black flex justify-between m-bottom-4">
              <div class="flex flex-direction-column" style="align-self: stretch;">
                <div class="text-clamp-2" style="max-height: 44px">{{ college.ipedsCollegeName }}</div>
                <div class="college_level_container text-caption2 text-t4" style="display: flex; align-items: center; align-content: center; gap: 4px; align-self: stretch; flex-wrap: wrap;">
                  <p class="flex items-center text-caption2 text-t4 flex-wrap m-top-8" style="max-height: 44px;">
                    {{ college.level }}
                    <span class="flex items-center">
                      <span class="w-4 h-4 bg-t2 radius-200 inline-block m-x-4"></span>
                      {{ college.addressCity }}, {{ college.addressState }}
                    </span>
                  </p>
                </div>
              </div>
            </strong>
          </div>
          <div>
            <img :src="college.imgUrl" alt="" @error="handleThumbnailError" class="college-img" style="width: 64px; height: 64px; border-radius: 8px; min-width: 64px; min-height: 64px;">
          </div>
        </div>
        <div class="p-x-16">
          <div class="flex items-center justify-between">
            <UiButton
              v-if="!myCollegeList.find(my => my.ipedsCollegeId === college.ipedsCollegeId)"
              class="light-primary small w-100-p"
              @click.stop="handleAddMyCollege(college.ipedsCollegeId)"
            >
              Add to College List
              <IconHeart class="m-left-8" width="16" height="16" color="#0038FF"/>
            </UiButton>
            <UiButton
              v-else
              class="light-primary small w-100-p"
              @click.stop="handleDeleteMyCollege(college.ipedsCollegeId)"
            >
              Remove from College List
              <IconHeartFill class="m-left-8" width="16" height="16" color="#0038FF"/>
            </UiButton>
          </div>
        </div>
      </article>
      <article
        @click="navigateTo('/college/find')"
        class="radius-16 p-x-20 border-a-primary2-2 bg-white box-border flex flex-direction-column justify-center cursor-pointer"
        style="width: calc(25% - 9px);"
      >
        <h2 class="text-body1-b text-primary4 m-bottom-8 flex items-end">
          <IconCollege width="32" height="32" viewBox="-2 -2 27 27" color="#99AFFF" class="m-right-8"/>
          See more colleges!
        </h2>
        <p class="flex items-center justify-between text-caption1-b text-primary5">
          Go to College Finder
          <img src="@share/assets/images/main/main-more-primary.svg" alt="">
        </p>
      </article>
    </div>

  </article>

</template>

<style scoped lang="scss">
.college_name_container {
  display: block;
  text-overflow: ellipsis;
  box-sizing: border-box;
  word-break: break-word;
  white-space: normal;
  word-wrap: break-word;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.college_name_ellipisis {
  -webkit-line-clamp: 2;
  text-overflow: ellipsis;
}

.college_level_container {
  display: block;
  text-overflow: ellipsis;
  box-sizing: border-box;
  word-break: break-word;
  white-space: normal;
  word-wrap: break-word;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

</style>