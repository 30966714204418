import { executeApi, useApi } from '../../composables/useApi'
import { computed, ref } from 'vue'
import { createSharedComposable } from '@vueuse/core'
import { storeToRefs } from 'pinia'
import { useMemberSessionStore } from '../../stores/memberSessionStore'
import { ResCollegeNicheIpeds } from '../../openapi'
import {useRuntimeConfig} from 'nuxt/app'
import defaultCollegeImage from '../../assets/images/college/college-default.svg'
 
export const useMainStepService =  createSharedComposable( () => {
  const api = useApi()
  const mainStep = ref({
 
  })
 
  const fetchMainStep = async () => {
    await executeApi(async () => {
      const { data } = await api.main.getFrontStepInfo()
      mainStep.value = data
    })
  }
 
  const isAllStepComplete = computed(() => {
    return mainStep.value['frontStepInfo']?.every(step => step.stepComplete === true)
  })
 
  const isStepComplete = (stepName) => {
    const step = mainStep.value['frontStepInfo']?.find(item => item.stepName === stepName)
    return step ? step.stepComplete : false
  }
 
  return {
    mainStep,
    isAllStepComplete,
    isStepComplete,
    fetchMainStep
  }
})
 
export const useMainRecommendedCollegeService = createSharedComposable(() => {
  const api = useApi()
  const { isLoggedIn } = storeToRefs(useMemberSessionStore())
 
  const recommendedCollegeList = ref<ResCollegeNicheIpeds[]>([])
 
  const fetchRecommendedCollegeList = async () => {
    await executeApi(async () => {
      const { data } = isLoggedIn.value ? await api.main.getLoginCollegeRecommend() : await api.main.getCollegeRecommend()
      recommendedCollegeList.value = data
    })
  }
 
  const handleThumbnailError = (event) => {
    event.target.onerror = null;
    event.target.src = defaultCollegeImage;
  }
 
  return {
    handleThumbnailError,
    recommendedCollegeList,
    fetchRecommendedCollegeList
  }
})
export const useMainRecommendedActivityService = createSharedComposable(() => {
  const api = useApi()
 
  const recommendedActivityList = ref([])
 
  const fetchRecommendedActivityList = async () => {
    await executeApi(async () => {
      const { data } = await api.main.getActivityRecommend()
      recommendedActivityList.value = data
    })
  }
 
  return {
    recommendedActivityList,
    fetchRecommendedActivityList
  }
})
 
export const useMainRecommendedCareerService = createSharedComposable(() => {
  const api = useApi()
 
  const recommendedCareerList = ref([])
 
  const fetchRecommendedCareerList = async () => {
    await executeApi(async () => {
      const { data } = await api.main.getCareerRecommend()
      recommendedCareerList.value = data
    })
  }
 
  return {
    recommendedCareerList,
    fetchRecommendedCareerList
  }
})
 
export const useMainRecommendedExpertService = createSharedComposable(() => {
  const api = useApi()

  const recommendedExpertList = ref([])
 
  const fetchRecommendedExpertList = async () => {
    await executeApi(async () => {
      const { data } = await api.main.getExpertsRecommend()
      recommendedExpertList.value = data
    })
  }
 
  return {
    recommendedExpertList,
    fetchRecommendedExpertList
  }
})

 
export const useMainPremiumActivityList = createSharedComposable(() => {
  const api = useApi()
  const premiumActivityList = ref()
  const fetchPremiumActivityList = async () => {
    await executeApi(async () => {
      const { data } = await api.main.getPremiumActivityList()
      premiumActivityList.value = data
    })
  }
 
  return {
    premiumActivityList,
    fetchPremiumActivityList
  }
})