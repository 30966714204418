<script setup lang="ts">

</script>

<template>
    <Swiper
      class="swiper-container"
      :modules="[SwiperAutoplay, SwiperPagination, SwiperNavigation]"
      :slides-per-view="1"
      :space-between="0"
      :loop="true"
      :autoplay="{
        delay: 3000,
        pauseOnMouseEnter: true
      }"
      :navigation="{ 
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev'
      }"
    >
     
      <!-- <SwiperSlide class="visual-slide">
        <NuxtLink to="/mentoring/find/researchprogram">
          <img src="/images/main/banner/banner-img1.svg" alt="" class="visual-slide-img" />
        </NuxtLink>
      </SwiperSlide> -->
      <SwiperSlide class="visual-slide">
        <NuxtLink to="/collegesurvey">
          <img src="/images/main/banner/banner-img6.svg" alt="" class="visual-slide-img" />
        </NuxtLink>
      </SwiperSlide>
      <!-- <SwiperSlide class="visual-slide">
        <NuxtLink to="/mentoring/find">
          <img src="/images/main/banner/banner-img2.svg" alt="" class="visual-slide-img" />
        </NuxtLink>
      </SwiperSlide> -->
      <SwiperSlide class="visual-slide">
        <NuxtLink to="/extracurriculars">
          <img src="/images/main/banner/banner-img3.svg" alt="" class="visual-slide-img" />
        </NuxtLink>
      </SwiperSlide>
      <SwiperSlide class="visual-slide">
        <NuxtLink to="/roadmap">
          <img src="/images/main/banner/banner-img4.svg" alt="" class="visual-slide-img" />
        </NuxtLink>
      </SwiperSlide>
      <SwiperSlide class="visual-slide">
        <NuxtLink to="/career/search">
          <img src="/images/main/banner/banner-img5.svg" alt="" class="visual-slide-img" />
        </NuxtLink>
      </SwiperSlide>
  
      <!-- Navigation buttons -->
      <div class="swiper-button-next">
        <img src="/images/main/chevron-right.svg" alt="Next">
      </div>
      <div class="swiper-button-prev">
        <img src="/images/main/chevron-left.svg" alt="Previous">
      </div>
    </Swiper>
  </template>
  

  <style lang="scss" scoped>
  :root {
    --swiper-pagination-bottom: 40px;
    --swiper-pagination-bullet-width: 10px;
    --swiper-pagination-bullet-height: 10px;
    --swiper-theme-color: #4D73FF;
    --swiper-pagination-bullet-inactive-opacity: 1;
    --swiper-pagination-bullet-inactive-color: #B8B8B8;
  }
  
  .visual-slide-img {
    width: 100%;
  }
  
  /* Custom styles for navigation buttons */
  .swiper-button-next, .swiper-button-prev {
    background: none; /* Remove default background */
    color: transparent; /* Remove any default color */
    outline: none; /* Remove default outline */
    cursor: pointer;
  
    &:after {
      display: none; /* Hide default Swiper arrow icons */
    }
  
    img {
      display: block; /* Ensure your custom icons are visible */
    }
  }
  
  /* Optional: Positioning and sizing for your custom icons */
  .swiper-button-prev {
    left: 40px;
    transform: translate(-50%, -50%);
    top: 60%;
  }
  .swiper-button-next {
    right: 40px;
    transform: translate(50%, -50%);
    top: 60%;
  }
  

  </style>
  