<script setup lang="ts">
import { useMemberProfileService } from 'share/services/member/useMemberProfileService'
import useMemberRoadmapService from 'share/services/member/useMemberRoadmapService'
import { useMemberSessionStore } from 'share/stores/memberSessionStore'
import _ from 'lodash'

const { memberInfo } = useMemberSessionStore()

const {
  memberProfile,
  getProfileImage,
  getGraduationDate,
  fetchMemberProfile
} = useMemberProfileService()

const {
  selectedRoadmapMemberId,
  selectedRoadmapData
} = useMemberRoadmapService()

onMounted( () => {
  fetchMemberProfile()
  selectedRoadmapMemberId.value = memberInfo.memberId
})

</script>

<template>
  <section class="rgba(255, 255, 255, 1) p-top-24 p-bottom-40 p-x-40">
    <header class="flex items-center justify-between m-bottom-20">
      <h1 class="text-title2-b">Your Progress at a Glance</h1>
      <UiTextButton class="text-700" @click="navigateTo('/mypond')">Go to My Pond</UiTextButton>
    </header>
    <div class="flex justify-between">
      <article
        class="bg-white radius-16 border-a-gray2-1 p-a-20 flex items-center box-border cursor-pointer"
        style="width: calc(33.33% - 8px)"
        @click="navigateTo('/mypond/profile')"
      >
        <div class="w-80 h-80 radius-200 inline-block m-right-12 flex-none">
          <img
            :src="getProfileImage(memberProfile.imageUrl)"
            alt="" class="fit-cover w-100-p h-100-p radius-200"
          >
        </div>
        <div>
          <h2 class="text-body1-b block">
            {{ memberProfile.memberName }}
          </h2>
          <p v-if="memberProfile.schoolName" class="m-top-8 text-body2 text-gray6">
            {{ memberProfile.schoolName }}
          </p>
          <p class="text-body2 text-t4">
            {{ getGraduationDate(memberProfile.graduationDate) }}
          </p>
        </div>
      </article>
      <article
        class="bg-white radius-16 border-a-gray2-1 p-a-20 m-left-12 box-border cursor-pointer"
        style="width: calc(33.33% - 8px)"
        @click="navigateTo('/mypond')"
      >
        <h2 class="text-body2-b m-bottom-16">Academic status</h2>
        <div>
          <div class="flex justify-between items-center">
            <strong class="text-caption2 text-primary4 text-700">Coursework Completion Rate</strong>
            <strong class="text-title2-b text-primary5">
              {{ selectedRoadmapData.roadmapCourseWork?.completionRate || 0 }}%
            </strong>
          </div>
          <div class="flex items-center justify-center m-top-12 box-border">
            <div class="progress-line">
                <span
                  class="progress-bar"
                  :style="`width: ${selectedRoadmapData.roadmapCourseWork?.completionRate || 0}%`"
                >
                </span>
            </div>
          </div>
        </div>
      </article>
      <article
        v-if="!_.isEmpty(selectedRoadmapData?.roadmapActivityCount)"
        class="bg-white radius-16 border-a-gray2-1 p-a-20 m-left-12 box-border cursor-pointer"
        style="width: calc(33.33% - 8px)"
        @click="navigateTo('/mypond?roadMapActive=Activities')"
      >
        <h2 class="text-body2-b m-bottom-16">Activities status</h2>
        <div class="flex justify-center items-center gap-8">
          <div class="text-caption2-b text-primary4 text-center" style="min-width: 4.51vw">
            All
            <strong class="text-title2-b text-primary6 m-top-4 block">
              {{
                selectedRoadmapData.roadmapActivityCount?.upcomingCnt
                + selectedRoadmapData.roadmapActivityCount?.currentCnt
                + selectedRoadmapData.roadmapActivityCount?.completedCnt
              }}
            </strong>
          </div>
          <UiSeparator class="h-47 m-x-8" style="width: 1px"/>
          <div class="text-caption2-b text-primary4 text-center" style="width: 4.51vw">
            Unscheduled
            <strong class="text-title2-b text-primary6 m-top-4 block">
              {{ selectedRoadmapData.roadmapActivityCount?.upcomingCnt }}
            </strong>
          </div>
          <UiSeparator class="h-47 m-x-8" style="width: 1px"/>
          <div class="text-caption2-b text-primary4 text-center" style="width: 4.51vw">
            Scheduled
            <strong class="text-title2-b text-primary6 m-top-4 block">
              {{ selectedRoadmapData.roadmapActivityCount?.currentCnt }}
            </strong>
          </div>
          <UiSeparator class="h-47 m-x-8" style="width: 1px"/>
          <div class="text-caption2-b text-primary4 text-center" style="width: 4.51vw">
            Completed
            <strong class="text-title2-b text-primary6 m-top-4 block">
              {{ selectedRoadmapData.roadmapActivityCount?.completedCnt }}
            </strong>
          </div>
        </div>
      </article>
    </div>
  </section>
</template>

<style scoped lang="scss">
@include progress(calc(100%),8px,$bg-color: $gray2, $active-bg-color: $primary5, $number-width: 0, $space: 0, $radius: 100px);

</style>
