<script setup lang="ts">
</script>

<template>
  <Swiper
    :modules="[SwiperAutoplay, SwiperPagination]"
    :slides-per-view="1"
    :space-between="0"
    :loop="true"
    :autoplay=" {
      delay: 3000,
      pauseOnMouseEnter: true
    }"
    :pagination="{ clickable: true }"
  >
  <SwiperSlide class="visual-slide">
      <NuxtLink
        to="/collegesurvey"
        class="position-absolute"
        style="width: 8.418%; height: 8%; left: 3.4%; bottom: 33.2%;"
      >
      </NuxtLink>
      <img
        src="/images/main/visual-img3.svg"
        alt=""
        class="visual-slide-img"
      >
    </SwiperSlide>
    <SwiperSlide class="visual-slide">
      <NuxtLink
        to="/career/survey"
        class="position-absolute"
        style="width: 8.418%; height: 8%; left: 3.4%; bottom: 36.8%;"
      >
      </NuxtLink>
      <img
        src="/images/main/visual-img1.svg"
        alt=""
        class="visual-slide-img"
      >
    </SwiperSlide>
    <SwiperSlide class="visual-slide">
      <NuxtLink
        to="/roadmap"
        class="position-absolute"
        style="width: 8.418%; height: 8%; left: 3.4%; bottom: 29.8%;"
      >
      </NuxtLink>
      <img
        src="/images/main/visual-img2.svg"
        alt=""
        class="visual-slide-img"
      >
    </SwiperSlide>
  </Swiper>
</template>

<style lang="scss">
:root {
  --swiper-pagination-bottom: 40px;
  --swiper-pagination-bullet-width: 10px;
  --swiper-pagination-bullet-height: 10px;
  --swiper-theme-color: #4D73FF;
  --swiper-pagination-bullet-inactive-opacity: 1;
  --swiper-pagination-bullet-inactive-color: #B8B8B8;
}
.visual-slide-img {
  width: 100%;
}
</style>
