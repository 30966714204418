<template>
    <UiIconBase>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M5 1.66659C5 0.930206 5.59695 0.333252 6.33333 0.333252H19.6667C20.403 0.333252 21 0.930206 21 1.66659V14.3333C21 17.2788 18.6122 19.6666 15.6667 19.6666H10.3333C7.38781 19.6666 5 17.2788 5 14.3333V1.66659ZM7.66667 2.99992V14.3333C7.66667 15.806 8.86057 16.9999 10.3333 16.9999H15.6667C17.1394 16.9999 18.3333 15.806 18.3333 14.3333V2.99992H7.66667Z" fill="#99AFFF"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M9 18.3333C9 17.597 9.59695 17 10.3333 17H15.6667C16.403 17 17 17.597 17 18.3333V21.6667C17 22.403 16.403 23 15.6667 23H10.3333C9.59695 23 9 22.403 9 21.6667V18.3333ZM11.6667 19.6667V20.3333H14.3333V19.6667H11.6667Z" fill="#99AFFF"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M10.3281 23.6665H15.6615V26.3332H10.3281V23.6665Z" fill="#99AFFF"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M5 22.9999C5 21.5272 6.19391 20.3333 7.66667 20.3333H18.3333C19.8061 20.3333 21 21.5272 21 22.9999V28.3333C21 29.0696 20.403 29.6666 19.6667 29.6666H6.33333C5.59695 29.6666 5 29.0696 5 28.3333V22.9999ZM18.3333 22.9999H7.66667V26.9999H18.3333V22.9999Z" fill="#99AFFF"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M18.3281 4.33333C18.3281 3.59695 18.9251 3 19.6615 3H21.9201C24.3543 3 26.2241 5.15595 25.8799 7.56569L25.3085 11.5657C25.027 13.5363 23.3393 15 21.3487 15H19.6615C18.9251 15 18.3281 14.403 18.3281 13.6667V4.33333ZM20.9948 5.66667V12.3333H21.3487C22.0122 12.3333 22.5748 11.8454 22.6686 11.1886L23.24 7.18856C23.3548 6.38532 22.7315 5.66667 21.9201 5.66667H20.9948Z" fill="#99AFFF"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M7.66406 4.33333C7.66406 3.59695 7.06711 3 6.33073 3H4.07209C1.63789 3 -0.231957 5.15595 0.11229 7.56569L0.683719 11.5657C0.965233 13.5363 2.65292 15 4.64352 15H6.33073C7.06711 15 7.66406 14.403 7.66406 13.6667V4.33333ZM4.9974 5.66667V12.3333H4.64352C3.97998 12.3333 3.41742 11.8454 3.32358 11.1886L2.75216 7.18856C2.63741 6.38532 3.26069 5.66667 4.07209 5.66667H4.9974Z" fill="#99AFFF"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M3.66406 27L22.6621 27L22.6621 29.6667L3.66406 29.6667L3.66406 27Z" fill="#99AFFF"/>
</UiIconBase>
</template>