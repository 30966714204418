<script setup lang="ts">
import { onMounted, ref } from 'vue'
import { useModal } from 'vue-final-modal'
import TermsAndConditions from '~/components/modal/auth/TermsAndConditions.vue'

import minerva from '@share/assets/images/main/partnerlogo/minerva.svg'
import forage from '@share/assets/images/main/partnerlogo/forage.svg'
import younoodle from '@share/assets/images/main/partnerlogo/younoodle.svg'
import niche from '@share/assets/images/main/partnerlogo/niche.svg'
import hyundai from '@share/assets/images/main/partnerlogo/hyundai.svg'
import kyungdong from '@share/assets/images/main/partnerlogo/kyungdong.svg'
import hyuholdings from '@share/assets/images/main/partnerlogo/hyuholdings.svg'
import nextchallenge from '@share/assets/images/main/partnerlogo/nextchallenge.svg'
import kic from '@share/assets/images/main/partnerlogo/kic.svg'


const originalLogoList = [minerva, forage, younoodle, niche , hyundai, kyungdong, hyuholdings, nextchallenge, kic]

const handleShowTermsModal = () => {
  const { open, destroy } = useModal({
    component: TermsAndConditions,
    attrs: {
      onConfirm: () => destroy(),
    },
  })
  open()
}

const goToPrivacyPolicy = () => {
  window.open('https://www.iubenda.com/privacy-policy/18448319', '_blank')
}

const gotoCookiePolicy = () => {
  window.open('https://www.iubenda.com/privacy-policy/18448319/cookie-policy', '_blank')
}

const emailUs = () => {
  window.open('mailto::customerservice@letitu.io', '_blank')
}
</script>

<template>
  <div class="p-left-40 m-bottom-40">
    <div class="p-top-24 border-top-gray1-1">
      <div class="flex" style="justify-content: space-between; flex-wrap: wrap;">
        <div class="w-50-p">
          <div class="flex m-bottom-12" style="gap: 8px">
            <button class="link-text first" @click="handleShowTermsModal">Terms</button>
            <button class="link-text" @click="goToPrivacyPolicy">Privacy</button>
            <button class="link-text" @click="gotoCookiePolicy">Cookies</button>
            <button class="link-text" @click="emailUs">Contact us</button>
          </div>
          <div class="copyright-text">©Letitu Corp. All rights reserved</div>
        </div>
        <div class="w-40-p m-right-40 flex" style="flex-direction: column;">
          <div class="trustworthy-partners m-bottom-4">Our Trustworthy Partners</div>

          <div class="flex items-right" style="gap: 16px;">
            <img v-for="(logo, index) in originalLogoList.slice(0, 4)" :src="logo" :key="index" />
          </div>

          <div class="flex items-right" style="gap: 16px; margin-top: 8px;">
            <img v-for="(logo, index) in originalLogoList.slice(4)" :src="logo" :key="index" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.trustworthy-partners {
  color: var(--gray-gray-6, #434247);

/* caption3-r */
font-family: Roboto;
font-size: 10px;
font-style: normal;
font-weight: 400;
line-height: 14px; /* 140% */
letter-spacing: 0.04px;
}

.link-text {
  @include set-text(12px, 400, 16px);
  color: #17171b;
  position: relative; // This ensures that the pseudo-element is positioned relative to the link-text
  padding: 0 16px; // Adds 8px padding to the left and right of the text
  &.first {
    padding-left: 0;
  }

  &:not(:last-child)::after {
    content: '';
    position: absolute;
    right: -7px;
    bottom: 0;

    width: 1px;
    height: 80%;
    background-color: #eaeaea;
  }
}

.copyright-text {
  color: #999;
  @include set-text(12px, 400, 16px);
}
</style>
