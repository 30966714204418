<script setup>
import { useMemberSessionStore } from 'share/stores/memberSessionStore'
import { useVfm } from 'vue-final-modal'
import { storeToRefs } from 'pinia'
import {
  useMainRecommendedActivityService,
  useMainRecommendedCareerService,
  useMainRecommendedCollegeService,
  useMainRecommendedExpertService,
  useMainStepService,
} from 'share/services/main/useMainService'


const { isLoggedIn, memberInfo } = storeToRefs(useMemberSessionStore())
const fetched = ref(false)

const { isAllStepComplete, fetchMainStep } = useMainStepService()

const { fetchRecommendedCollegeList } = useMainRecommendedCollegeService()

const { fetchRecommendedActivityList } = useMainRecommendedActivityService()

const { fetchRecommendedCareerList } = useMainRecommendedCareerService()

const { fetchRecommendedExpertList } = useMainRecommendedExpertService()

const vfm = useVfm()
vfm.closeAll()

onMounted(async () => {
  // localStorage.setItem("AccessPage", 2);
  if (isLoggedIn.value) {
    fetchMainStep()
  }
  await Promise.all([
    fetchRecommendedCollegeList(),
    fetchRecommendedActivityList(),
    fetchRecommendedCareerList(),
    fetchRecommendedExpertList(),
  ])
  fetched.value = true
})

if (process.client) {
  localStorage.setItem('AccessPage', 2)
}
</script>

<template>
  <PageMainVisualSlide v-if="!isLoggedIn" />
  <PageMainBannerSlide v-if="isLoggedIn" />

  <!--  로그인 O && The Pond Step guide (완료 전) -->
  <PageMainStepGuide v-if="isLoggedIn && !isAllStepComplete" />
  <!--  로그인 O && The Pond Step guide (완료) -->
  <PageMainMyPond v-if="isLoggedIn && isAllStepComplete" />
  <PageMainDeadlineDisplay v-if="isLoggedIn" />
  <PageMainRecommendedColleges />
  <PageMainRecommendedActivities />
  <PageMainRecommendedCareers />
  <!-- <PageMainRecommendedExperts /> -->
  <PageMainCollegeLogos v-if="fetched" />
  <PageMainPartnerLogos />
</template>
