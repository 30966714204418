<script setup lang="ts">
import { onMounted, ref } from 'vue'

import nyu from '@share/assets/images/main/collegelogo/nyu.svg'
import ucberkeley from '@share/assets/images/main/collegelogo/ucberkeley.svg'
import harvard from '@share/assets/images/main/collegelogo/harvard.svg'
import princeton from '@share/assets/images/main/collegelogo/princeton.svg'
import mit from '@share/assets/images/main/collegelogo/mit.svg'
import northwestern from '@share/assets/images/main/collegelogo/northwestern.svg'
import stanford from '@share/assets/images/main/collegelogo/stanford.svg'
import upenn from '@share/assets/images/main/collegelogo/upenn.svg'
import cornell from '@share/assets/images/main/collegelogo/cornell.svg'
import columbia from '@share/assets/images/main/collegelogo/columbia.svg'
import rice from '@share/assets/images/main/collegelogo/rice.svg'
import emory from '@share/assets/images/main/collegelogo/emory.svg'
import ucla from '@share/assets/images/main/collegelogo/ucla.svg'
import usc from '@share/assets/images/main/collegelogo/usc.svg'
import notredame from '@share/assets/images/main/collegelogo/notredame.svg'
import carnegie from '@share/assets/images/main/collegelogo/carnegie.svg'

const originalLogoList = [
  nyu,
  ucberkeley,
  harvard,
  princeton,
  mit,
  northwestern,
  stanford,
  upenn,
  cornell,
  columbia,
  rice,
  emory,
  ucla,
  usc,
  notredame,
  carnegie,
]

const extendedLogoList = ref([...originalLogoList])
const extendLogoList = () => {
  extendedLogoList.value.push(...originalLogoList)
}

onMounted(() => {
  // Set an interval to extend the list periodically
  const interval = setInterval(extendLogoList, 1500) // Adjust time as needed

  // Clear interval on component unmount
  onUnmounted(() => {
    clearInterval(interval)
  })
})
</script>

<template>
  <div class="p-x-40 m-bottom-40">
    <div class="p-y-16 p-x-20 bg-primary1 flex" style="padding-bottom: 24px">
      <div
        class="text-body1-b flex items-center"
        style="
          width: 200px;
          color: var(--gray-black, #17171b);

          /* body1-b */
          font-family: Roboto;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 22px; /* 137.5% */
          letter-spacing: 0.024px;
        "
      >
        The Pond
        <br />
        Admission History
      </div>
      <div class="logo-slider">
        <div class="logo-track">
          <div class="logo-holder" v-for="(logo, index) in extendedLogoList" :key="index">
            <img :src="logo" alt="logo" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.logo-slider {
  overflow: hidden;
  width: 100%;
}

.logo-track {
  display: flex;
  animation: slide 60s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  width: calc(100% * 4);
}

@keyframes slide {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}

.logo-holder {
  width: 90px;
  height: 90px;
  background-color: white;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
}

.logo-track .logo-holder {
  flex: 0 0 auto;
}
</style>
